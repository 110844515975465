<template>
    <v-container dark style="color: white" class="text-left">
        <v-card class="mx-auto mt-5 mb-5" width="1000">
            <v-card-text>
                <v-row>
                    <v-col>
                        <div class="display-1">
                            Privacy Policy
                        </div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" v-for="item in privacyPolicy" :key="item.title">
                        <p  class="headline">
                            {{item.title }}
                        </p>
                        <p>
                            {{ item.text }}
                        </p>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>

export default {
    name: 'PrivacyPolicy',
    data: () => ({
        privacyPolicy: [
            {
                title: 'Introduction',
                text: 'Welcome to our website dedicated to the collection of "Slivers" in the Magic: The Gathering card game. We highly value your privacy and are committed to providing you with a secure and enjoyable experience. This Privacy Policy outlines how we collect, use, and protect your personal information.'
            },
            {
                title: 'Information We Collect',
                text: 'We collect your email address solely for identification purposes and to provide you with notification updates regarding your Sliver card collection. We do not share, sell, or disclose your email address or any other information under any circumstances. For user-specific functionalities, we use local storage to store session tokens and information. Google Analytics is used for user flow'
            },
            {
                title: 'Use of Information',
                text: 'Your email address is used exclusively to send you notifications and updates related to the website and your Sliver card collection. We do not use your email address for any other purpose or share it with third parties.'
            },
            {
                title: 'Data Security',
                text: 'We implement robust security measures to protect your information from unauthorized access, alteration, disclosure, or destruction. Your data is stored securely, and access is restricted to authorized personnel only.'
            },
            {
                title: 'User Control',
                text: 'Tienes la capacidad de controlar las notificaciones que recibes de nuestra plataforma a través de tu panel de usuario. Puedes actualizar tus preferencias en cualquier momento.'
            },
            {
                title: 'Contact Us',
                text: 'If you have any questions or concerns about our privacy practices, please contact us at contact@slivers.app.'
            },
            {
                title: 'Changes to this Privacy Policy',
                text: 'We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. Any such changes will be posted on this page.'
            },
            {
                title: 'Last Updated',
                text: 'Nov 23, 2023'
            },
        ],
    }),
}
</script>

