<template>
    <v-row>
        <v-dialog v-model="dialogToggle" max-width="650px" @change="handleInput">
            <v-card>
                <v-progress-linear
                    :active="loading"
                    :indeterminate="loading"
                    absolute
                    top
                    color="primary"
                ></v-progress-linear>
                <v-card-title>
                    <span class="text-h5"> {{ cardName }}</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-img
                                    max-width="250"
                                    :src="cardImage"
                                >
                                    <div class="gradient" v-show="showFoil"></div>
                                </v-img>
                            </v-col>
                            <v-col cols="12" sm="6" class="text-center">
                                <v-text-field
                                    v-model="cardSet"
                                    label="Edition"
                                    outlined
                                    readonly
                                    dense
                                ></v-text-field>
                                
                                <v-select
                                    v-model="language"
                                    :items="languages"
                                    label="Language"
                                    outlined
                                    dense
                                ></v-select>

                                <v-select
                                    v-model="condition"
                                    :items="conditions"
                                    label="Condition"
                                    outlined
                                    dense
                                ></v-select>

                                <v-switch
                                    v-model="isFoilSwitch"
                                    :label="foilVerbose"
                                    :disabled="hasFoilSwitch"
                                    inset
                                ></v-switch>
                                
                                <div class="text-center">
                                    <p>Amount</p>
                                    <v-btn
                                        class="mx-2"
                                        fab
                                        dark
                                        small
                                        @click="subAmount"
                                    >
                                        <v-icon dark>
                                            mdi-minus
                                        </v-icon>
                                    </v-btn>
                                    <v-chip
                                        class="ma-2"
                                        large
                                    >
                                     {{ amount }}
                                    </v-chip>
                                
                                    <v-btn
                                        class="mx-2"
                                        fab
                                        dark
                                        small
                                        @click="addAmount"
                                    >
                                        <v-icon dark>
                                            mdi-plus
                                        </v-icon>
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" text @click="closeDialog()">
                        Back
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="saveItemCollection()">
                        Add
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
export default {
    props: {
        idCard: String,
        value: Boolean
    },

    data: () => ({
        dialogToggle: false,
        isFoilSwitch: false,
        hasFoilSwitch: true,
        loading: false,
        conditions: [
            'Mint(M)',
            'Near Mint(NM)',
            'Excellent(EX)',
            'Good(GD)',
            'Light Played(LP)',
            'Played(PL)',
            'Poor',
        ],
        languages: [
            "English",
            "Spanish",
            "German",
            "Simplified Chinese",
            "French",
            "Italian",
            "Japanese",
            "Portuguese"
        ],
        editions: [
            'Tempest Remastered',
            'PDS: Slivers',
            'Stronghold',
        ],
        amount: 1,
        condition: 'Mint(M)',
        language: 'English'
    }),

    methods: {
        closeDialog () {
            this.dialogToggle = false
        },

        addAmount() {
            this.amount += 1
        },

        subAmount() {
            this.amount -= 1

            if (this.amount < 0)
                this.amount = 0
        },

        handleInput() {
            this.$emit('input', this.dialogToggle)
        },
        
        handleFoilSwitch() {
            const { finishes } = this.sliverView

            if (finishes.length == 2) {
                this.hasFoilSwitch = false
            } else {
                this.isFoilSwitch = finishes.includes('foil')
                this.hasFoilSwitch = true
            }
        },

        async saveItemCollection () {
            if (this.amount <= 0)
                this.amount = 1

            const payload = {
                itemId: this.cardId,
                name: this.cardName,
                type: "card",
                info: {
                    amount: +this.amount,
                    condition: this.condition,
                    leng: this.language,
                    isFoil: this.isFoilSwitch
                }
            }

            this.loading = true
            await this.$store.dispatch('addItemCollection', payload)
            this.amount = 1
            this.condition = 'Mint(M)'
            this.language = 'English'
            this.loading = false
            this.isFoilSwitch = false

            this.closeDialog()
        }
    },

    computed: {
        foilVerbose () {
            return this.isFoilSwitch ? 'Foil' : 'Not Foil'
        },

        sliverView() {
            return this.$store.getters.sliverCardByUuid(this.idCard)
        },

        cardId () {
            return this.sliverView?._id
        },

        cardName () {
            return this.sliverView?.name
        },

        cardImage () {
            return this.sliverView?.image_uris?.png
        },

        cardSet () {
            return this.sliverView?.set_name
        },

        showFoil () {
            return this.isFoilSwitch
        }
    },

    watch: {
        value (val) {
            this.dialogToggle = val
        },

        dialogToggle() {
            this.handleInput()
            this.handleFoilSwitch()
        },

        amount (val) {
            if (val < 0)
                this.amount = +1
        }

    },

    created () {
        
    }

    
}
</script>


<style>
.gradient {
    background: linear-gradient(to bottom, #ff0000 0%, #00ff00 33%, #0000ff 66%, #ff0000 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff0000', endColorstr='#ff0000', GradientType=0);
    /* IE6-9 */
    background-position: 0 0;
    height: 100%;
    opacity: .25;
    width: 100%;
    animation: colorscroll 4s linear infinite;
}

@keyframes colorscroll {
    from {
        background-position: 0 0;
    }

    to {
        background-position: 0 350px;
    }
}
</style>