<template>
   <v-card flat class="text-left">
        <v-card-text>
            <v-checkbox
                v-model="updateCheckbox"
                label="Receive e-mails about news and updates (2 - 4 e-mails per year)"
            >
            </v-checkbox>
            <v-checkbox
                v-model="itemsCheckbox"
                label="Receive notification when new items are added"
            ></v-checkbox>
        </v-card-text>

        <v-card-actions>
            <v-btn
                color="primary"
                class="ma-2"
                @click="updatePreferences"
            >
                Update E-mail Preferences
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    export default {
        name: 'UserEmail',

        data: () => ({
            updateCheckbox: false,
            itemsCheckbox: false
        }),

        computed: {
            loggedIn() {
                return this.$store.getters.loggedIn
            },

            user() {
                return this.$store.getters.user
            },

        },

        methods: {
            async updatePreferences () {
                const payload = {
                    updates: this.updateCheckbox,
                    items: this.itemsCheckbox
                }

                await this.$store.dispatch('updateEmailNotifcations', payload)
            }
        },

        created () {
            this.updateCheckbox = this.user.emailNotifcations.updates
            this.itemsCheckbox = this.user.emailNotifcations.items
        }
    }
</script>