<template>
    <v-container
        fluid
    >
        <v-row
            no-gutters
            align="center"
            justify="center"
        >
            <v-col
                cols="12"
                align="center"
                justify="center"
            >
                <v-card
                    class="mx-auto my-15"
                    max-width="600"
                    outlined
                >
                    <v-progress-linear
                        :active="loading"
                        :indeterminate="loading"
                        absolute
                        bottom
                        color="primary"
                    ></v-progress-linear>
                    
                    <div class="text-left mb-4 mt-8 ml-7">
                        <span class="text-h6 text--secondary">
                            <img
                                :src="require('../assets/logo-text.png')"
                                alt="SLiver Logo"
                                width="150"
                                style="vertical-align: middle;"
                                class="mr-1"
                            >
                        </span>
                    </div>
                    <div class="text-left text-h5 ml-7">
                        Create your Account
                    </div>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="8" v-if="!singupDone">
                                    <v-form
                                        ref="form"
                                        v-model="valid"
                                        lazy-validation
                                    >   <v-row>
                                            <v-col cols="12">
                                                <v-text-field
                                                    label="Username"
                                                    name="Username"
                                                    type="text"
                                                    v-model="username"
                                                    :rules="userRules"
                                                    v-on:keyup.enter="validate"
                                                    dense
                                                    outlined
                                                    required
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-text-field
                                                    label="Email"
                                                    name="Email"
                                                    type="text"
                                                    hint="You'll need to confirm that this email belongs to you."
                                                    v-model="email"
                                                    :rules="emailRules"
                                                    v-on:keyup.enter="validate"
                                                    dense
                                                    outlined
                                                    required
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-text-field
                                                    label="Password"
                                                    name="password"
                                                    v-model="password"
                                                    :rules="passRules"
                                                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                                    :type="show1 ? 'text' : 'password'"
                                                    @click:append="show1 = !show1"
                                                    v-on:keyup.enter="validate"
                                                    dense
                                                    outlined
                                                    required
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-text-field
                                                    label="Confirm"
                                                    name="Confirm"
                                                    v-model="passwordComfirm"
                                                    :rules="confirmationRules"
                                                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                                    :type="show1 ? 'text' : 'password'"
                                                    @click:append="show1 = !show1"
                                                    v-on:keyup.enter="validate"
                                                    dense
                                                    outlined
                                                    required
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                    <div align="center" style="color:#E53935" class="font-italic">
                                        {{ errorSingup }}
                                    </div>
                                </v-col>
                                
                                <v-col cols="12" sm="8" align="center" justify="center" v-else>
                                    Account has succesfully been created. An email will arrive shortly to confirm the user creation.
                                </v-col>
                                <v-col cols="12" sm="4" align="center" justify="center">
                                    <img
                                        :src="require('../assets/alone-01.png')"
                                        alt="SLiver Logo"
                                        width="150"
                                        style="vertical-align: middle;"
                                        class="mr-1"
                                    >
                                </v-col>
                                
                            </v-row>
                        </v-container>
                    </v-card-text>

                    <v-card-actions class="mb-7">
                        <v-btn
                            v-show="!singupDone"
                            text
                            color="primary"
                            class="ma-2"
                            to="/singin"
                        >
                            Sign in instead 
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            v-show="!singupDone"
                            color="primary"
                            class="ma-2"
                            :disabled="loading || !valid"
                            @click="validate"
                        >
                            Create
                        </v-btn>
                        <v-btn
                            v-show="singupDone"
                            color="primary"
                            class="ma-2"
                            to="/singin"
                        >
                            Sing In
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        
        <v-row
            justify="center"
            style="margin-top: -35px;"
        >
            <v-btn small text to="/contact">
                Help
            </v-btn>
            <v-btn small text to="/policy">
                Privacy
            </v-btn>
        </v-row>
        <v-row
            align="end"
            justify="center"
            style="margin-top: 35px;"
        >
            <p class="font-weight-light">
                Copyright © {{currentYear}} — Sliver Collector 
            </p>
        </v-row>
    </v-container>
</template>

<script>
// TODO: Crear evaluacion de confirmacion de password
export default {
    data: () => ({
        valid: false,
        show1: false,
        loading: false,
        passRules: [
            v => !!v || 'Password is required',
            v => (v && v.length >= 8) || 'Password must be at least 8 characters',
        ],
        email: 'tedted132@ted.cl',
        password: '12345423234234',
        passwordComfirm: '12345423234234',
        username: 'Ted Ted',
        errorSingup: '',
        takenEmail: '',
        takenUser: '',
        singupDone: false,
    }),
    
    methods: {
        async validate() {
            const isValid = this.$refs.form.validate()

            if (!isValid)
                return

            const payload = {
                email: this.email,
                password: this.password,
                username: this.username
            }
            
            this.loading = true
            const response = await this.$store.dispatch('singupUser', payload)
            this.loading = false
            
            this.errorSingup = response.success ? '' : response.msg

            if (this.errorSingup.includes("Email"))
                this.takenEmail = this.email

            if (this.errorSingup.includes("User")) {
                this.takenUser = this.username
            }

            if (response.success)
                this.singupDone = true
        },
    },

    computed: {
        confirmationRules() {
            return [
                v => v === this.password || 'Passwords do not match'
            ]
        },

        emailRules () {
            return [
                v => !!v || 'Email is required',
                v => /.+@.+\..+/.test(v) || 'Email must be valid',
                v => v !== this.takenEmail || 'Email already exists'
            ]
        },

        userRules () {
            return [
                v => !!v || 'User is required',
                v => v !== this.takenUser || 'User already exists'
            ]
        },

        currentYear() {
            return '2023'
        }
    },
    
    created() {
        // HIDEN NAVEGATOR
    }

}
</script>