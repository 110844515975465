<template>
    <v-container
        fluid
    >
        <v-row
            no-gutters
            align="center"
            justify="center"
        >
            <v-col
                cols="12"
                align="center"
                justify="center"
            >
                <v-card
                    class="mx-auto my-15"
                    max-width="600"
                    outlined
                >
                    <v-progress-linear
                        :active="loading"
                        :indeterminate="loading"
                        absolute
                        bottom
                        color="primary"
                    ></v-progress-linear>
                    
                    <div class="text-left mb-4 mt-8 ml-7">
                        <span class="text-h6 text--secondary">
                            <img
                                :src="require('../assets/logo-text.png')"
                                alt="SLiver Logo"
                                width="150"
                                style="vertical-align: middle;"
                                class="mr-1"
                            >
                        </span>
                    </div>
                    <div class="text-left text-h5 ml-7">
                        Recover your Account
                    </div>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="8" v-if="!recoverDone">
                                    <v-form
                                        ref="form"
                                        v-model="valid"
                                        lazy-validation
                                    >   <v-row>
                                            <v-col cols="12" align="center" justify="center">
                                                <v-text-field
                                                    label="Email"
                                                    name="Email"
                                                    type="text"
                                                    hint="A link will be sent with your new password."
                                                    v-model="email"
                                                    :rules="emailRules"
                                                    v-on:keyup.enter="validate"
                                                    dense
                                                    outlined
                                                    required
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                    <div align="center" style="color:red">
                                        {{ errorRecover }}
                                    </div>
                                </v-col>
                                
                                <v-col cols="12" sm="8" align="center" justify="center" v-else>
                                    A link has been sent to your email to recover your account.
                                </v-col>
                                <v-col cols="12" sm="4" align="center" justify="center">
                                    <img
                                        :src="require('../assets/alone-01.png')"
                                        alt="SLiver Logo"
                                        width="150"
                                        style="vertical-align: middle;"
                                        class="mr-1"
                                    >
                                </v-col>
                                
                            </v-row>
                        </v-container>
                    </v-card-text>

                    <v-card-actions class="mb-7">
                        <v-btn
                            v-show="!recoverDone"
                            text
                            color="primary"
                            class="ma-2"
                            to="/singin"
                        >
                            Sign in instead 
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            v-show="!recoverDone"
                            color="primary"
                            class="ma-2"
                            :disabled="loading || !valid"
                            @click="validate"
                        >
                            Send
                        </v-btn>
                        <v-btn
                            v-show="recoverDone"
                            color="primary"
                            class="ma-2"
                            to="/singin"
                        >
                            Sing In
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        
        <v-row
            justify="center"
            style="margin-top: -35px;"
        >
            <v-btn small text to="/contact">
                Help
            </v-btn>
            <v-btn small text to="/policy">
                Privacy
            </v-btn>
        </v-row>
        <v-row
            align="end"
            justify="center"
            style="margin-top: 35px;"
        >
            <p class="font-weight-light">
                Copyright © {{currentYear}} — Sliver Collector
            </p>
        </v-row>
    </v-container>
</template>

<script>
// TODO: Crear evaluacion de confirmacion de password
export default {
    data: () => ({
        valid: false,
        loading: false,
        emailRules: [
            v => !!v || 'Email is required',
            v => /.+@.+\..+/.test(v) || 'Email must be valid',
        ],
        email: 'tedted132@ted.cl',
        errorRecover: '',
        recoverDone: false
    }),
    
    methods: {
        async validate() {
            const isValid = this.$refs.form.validate()

            if (!isValid)
                return

            const payload = {
                email: this.email
            }
            
            this.loading = true
            const response = await this.$store.dispatch('recoverUser', payload)
            this.loading = false
            
            this.errorRecover = response.success ? '' : response.msg

            if (response.success)
                this.recoverDone = true
        },
    },

    computed: {
        loggedIn() {
            return this.$store.getters.loggedIn
        },
        currentYear() {
            return '2023'
        }
    },

    watch: {
        loggedIn(val) {
            if (val)
                this.$router.push('/')
        }
    },

    created() {
        // HIDEN NAVEGATOR
    }

}
</script>