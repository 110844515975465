<template>
    <v-container class="pa-4 text-center">
        <v-row>
            <v-card
                class="mx-auto transparent"
                elevation="0"
                width="1000px"
                align="center" justify="center"
            >
                <v-row>
                    <v-col cols="12" sm="3">
                        <v-text-field
                            v-model="searchName"
                            label="Search"
                            placeholder="Card name"
                            outlined
                            dense
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2">
                        <v-select
                            v-model="sortValue"
                            :items="sortSelect"
                            item-text="name"
                            item-value="id"
                            label="Sorted by"
                            outlined
                            dense
                        ></v-select>
                    </v-col>
                    <v-col cols="12" sm="2">
                        <v-btn-toggle
                            v-model="sortType"
                            mandatory
                            dense
                        >
                            <v-btn>
                                <v-icon color="primary">mdi-sort-ascending</v-icon>
                            </v-btn>

                            <v-btn>
                                <v-icon color="primary">mdi-sort-descending</v-icon>
                            </v-btn>
                        </v-btn-toggle>
                    </v-col>
                    <v-col cols="12" sm="3" v-if="loggedIn">
                        <div style="margin-top: -10px;">
                            <v-switch
                                v-model="showCollection"
                                :label="inCollectionText"
                            ></v-switch>
                        </div>
                    </v-col>
                </v-row>
            </v-card>
        </v-row>
        <v-row>
            <v-card
                class="mx-auto transparent"
                elevation="0"
                width="1000px"
                min-height="600px"
                align="center" justify="center"
            >
                <div class="mb-10">
                    <div class="text-sm-left font-weight-thin">
                        {{ numberTextShowCards }} {{ textShowCards }} {{ textShowCollectionCards }}
                    </div>
                    <v-divider></v-divider>
                </div>
                <v-row>
                    <template v-for="(item, i) in showPages">
                        <v-col :key="i" cols="12" lg="3" md="3" sm="4" xs="6">
                            <v-hover v-slot="{ hover }">
                                <v-card
                                    :elevation="hover ? 12 : 2"
                                    :class="viewClass(item.inCollection, hover)"
                                    class="transparent"
                                    color="black"
                                    min-width="150"
                                    width="250"
                                    @click="viewCard(item.id_sf)"
                                >
                                    <v-img
                                        :src="item.image_uris.png"
                                        class="white--text align-end"
                                        min-width="150"
                                        width="250"
                                    >
                                    </v-img>
                                </v-card>
                            </v-hover>
                        </v-col>
                    </template>
                </v-row>
            </v-card>
        </v-row>
        <v-row class="fill-height mt-10" align="center" justify="center" v-show="numberPagues > 1">
            <v-pagination
                v-model="page"
                :length="numberPagues"
                color="primary"
            ></v-pagination>
        </v-row>
        <ViewCard v-model="dialogViewCard" :idCard="addCardId" typeCard="art"></ViewCard>
    </v-container>
</template>

<script>
import ViewCard from '@/components/ViewCard'

export default {
    data: () => ({
        sortSelect: [
            { id: "name", name:'Name' },
            { id: "released_at", name:'Release Date' },
            { id: "set_name", name:'Set' },
            { id: "rarity", name:'Rarity' },
            { id: "colors", name:'Color' },
        ],
        orderSelect: ['Auto', 'Desc', 'Asc'],
        transparent: 'rgba(255, 255, 255, 0)',
        dialogViewCard: false,
        addCardId: '',
        page: 1,
        searchName: '',
        showCard: 'All prints',
        sortValue: 'name',
        orderValue: 'Auto',
        sortType: 0,
        showCollection: false
    }),

    computed: {
        loggedIn() {
            return this.$store.getters.loggedIn
        },

        inCollectionText () {
            return this.showCollection ? 'Show Collection' : 'Hide Collection'
        },

        showCardSelect() {
            return ['Cards', 'All prints', 'Unique Art']
        },

        sliverCards () {
            return this.$store.getters.sliverCards.filter( e => e.type == 'art')
        },
        
        filterSliverCards () {
            let sortArtSliverCards = this.orderCards()
            return sortArtSliverCards.filter((cadena) => cadena.name.toLowerCase().includes(this.searchName.toLowerCase()))
        },

        hasCollection () {
            return this.$store.getters.collection.length > 0
        },

        showCardFilter () {
            let campoAFiltrar = ''

            if ( this.showCard === 'Cards' )
                campoAFiltrar = 'name'
            else if ( this.showCard === 'All prints' )
                campoAFiltrar = 'id_sf'
            else
                campoAFiltrar = 'illustration_id'

            return Array.from(new Set(this.filterSliverCards.map(obj => obj[campoAFiltrar])))
                .map(nombre => this.filterSliverCards.find(obj => obj[campoAFiltrar] === nombre));
        },

        showPages () {
            return this.showCardFilter.slice ((this.page - 1) * 20,  this.page * 20)
        },

        numberPagues () {
            return Math.ceil(this.showCardFilter.length / 20)
        },

        collectionSliver() {
            return this.showCardFilter.filter(e => e.inCollection )
        },

        initShowNumber() {
            return (this.page - 1) * 20 + 1
        },

        endShowNumber() {
            return this.numberPagues !== this.page ? (this.page * 20) : this.showCardFilter.length
        },

        numberTextShowCards() {
            return `${this.initShowNumber} – ${this.endShowNumber} of ${this.showCardFilter.length}`
        },

        textShowCards() {
            return this.searchName === "" ? 'Slivers art cards' : `Slivers art cards where the name includes “${this.searchName}”`
        },

        textShowCollectionCards() {
            if (!this.loggedIn)
                return ''

            return `· ${this.collectionSliver.length} in Collection`
        },

    },

    methods: {
        viewCard(idCard) {
            this.addCardId = idCard
            this.dialogViewCard = true
        },

        orderCards() {
            const order = this.orderValue === 'Auto' ? 'Asc' : this.orderValue

            return this.sliverCards.sort((a, b) => {
                let valueA = a[this.sortValue]
                let valueB = b[this.sortValue]

                if ( this.sortValue === 'colors' ) {
                    valueA = valueA.join()
                    valueB = valueB.join()
                }

                if (order === 'Asc') {
                    if (valueA < valueB) return -1
                    if (valueA > valueB) return 1
                    return 0
                } else if (order === 'Desc') {
                    if (valueA > valueB) return -1
                    if (valueA < valueB) return 1
                    return 0
                } else {
                    throw new Error('Error')
                }
            })
        },

        viewClass(inCollection, hover) {
            if ( !this.showCollection )
                return { 'on-hover': hover, "s-cards": true }

            if (inCollection)
                return { 'on-hover': hover }

            return { 'on-hover': hover, "s-cards-filter": this.showCollection }
        }
    },

    watch: {
        numberPagues ( ) {
            this.page = 1
        },

        sortType(val) {
            this.orderValue = val === 0 ? 'Asc' : 'Desc'
        }
    },
    
    components: {
        ViewCard
    },

    created() {
        if (this.loggedIn) {
            if ( !this.hasCollection )
                this.$store.dispatch('pullItemCollection')

            this.$store.dispatch('updateCollection', { type: 'card' })
            this.sortSelect.push({ id: "inCollection", name: 'Collection' })
        } else {
            const index = this.sortSelect.indexOf(e => e.id === 'inCollection')

            if ( index > -1 )
                this.sortSelect.splice(index, 1)
        }
    }
}
</script>

<style scoped>
.s-cards {
    transition: opacity .4s ease-in-out;
}

.s-cards:not(.on-hover) {
    opacity: 0.7;
}
.s-cards-filter {
    transition: opacity .4s ease-in-out;
    filter: grayscale(0);
}

.s-cards-filter:not(.on-hover) {
    filter: grayscale(1);
    opacity: 0.6;
}
.show-btns {
    color: #81C784 !important;
}
</style>