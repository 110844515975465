<template>
	<v-app style="background: #272727;">
		<dashboard-core-app-bar v-if="!isPublic"/>
		<dashboard-core-drawer v-if="!isPublic"/>
		<dashboard-core-view />
		<Notification></Notification>
	</v-app>
</template>


<script>

export default {
	name: 'App',
	components: {
		DashboardCoreAppBar: () => import('./components/BarApp'),
		DashboardCoreDrawer: () => import('./components/NavigationDrawer'),
		DashboardCoreView: () => import('./components/View'),
		Notification: () => import('./components/Notification'),
	},
	computed: {
		isPublic() {
			const publicPages = ['/singin', '/singup', '/recover', '/confirm']
			return publicPages.includes(this.$route.path)
		}
	},
}
</script>

<style lang="scss">
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
}
</style>
