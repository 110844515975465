<template>
    <v-container
        fluid
    >
        <v-row
            no-gutters
            align="center"
            justify="center"
        >
            <v-col
                cols="12"
                align="center"
                justify="center"
            >
                <v-card
                    class="mx-auto my-15"
                    max-width="400"
                    outlined
                >
                    <v-progress-linear
                        :active="loading"
                        :indeterminate="loading"
                        absolute
                        bottom
                        color="primary"
                    ></v-progress-linear>

                    <div class="text-center mb-4 mt-8">
                        <span class="text-h6 text--secondary">
                            <img
                                :src="require('../assets/logo-text.png')"
                                alt="SLiver Logo"
                                width="250"
                                style="vertical-align: middle;"
                                class="mr-1"
                            >
                            <!-- <span class="font-family-body">Sliver Collector</span> -->
                        </span>
                    </div>
                    <div class="text-h5">
                        Sign in
                    </div>
                    <p class="subtitle-1">
                        Use your sliver collector Account
                    </p>
                    <p class="caption" v-if="confirmExists">
                        {{ confirmValue }}
                    </p>
                    <v-card-text>
                        <v-form
                            ref="form"
                            v-model="valid"
                            lazy-validation
                        >
                            <v-text-field
                                label="Email"
                                name="Email"
                                type="text"
                                v-model="email"
                                :rules="emailRules"
                                v-on:keyup.enter="validate"
                                outlined
                                required
                            ></v-text-field>
                            <div class="text-end">
                                <router-link style="text-decoration:none;" to="/recover"><a >Forgot password?</a></router-link>
                            </div>
                            <v-text-field
                                id="password"
                                label="Password"
                                name="password"
                                v-model="password"
                                :rules="passRules"
                                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="show1 ? 'text' : 'password'"
                                @click:append="show1 = !show1"
                                v-on:keyup.enter="validate"
                                outlined
                                required
                            ></v-text-field>
                            
                        </v-form>
                        <div align="center" style="color:#E53935" class="font-italic">
                            {{errorLogin}}
                        </div>
                    </v-card-text>
                    <v-card-actions class="mb-7">
                        <v-btn
                            text
                            color="primary"
                            class="ma-2"
                            to="/"
                        >
                            Home
                        </v-btn>
                        <v-btn
                            text
                            color="primary"
                            class="ma-2"
                            to="/singup"
                        >
                            Create Account
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary"
                            class="ma-2"
                            :disabled="loading || !valid"
                            @click="validate"
                        >
                            Login
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        
        <v-row
            justify="center"
            style="margin-top: -35px;"
        >
            <v-btn small text to="/contact">
                Help
            </v-btn>
            <v-btn small text to="/policy">
                Privacy
            </v-btn>
        </v-row>
        <v-row
            align="end"
            justify="center"
            style="margin-top: 35px;"
        >
            <p class="font-weight-light">
                Copyright © {{currentYear}} — Sliver Collector 
            </p>
        </v-row>
    </v-container>
</template>

<script>

export default {
    data: () => ({
        valid: false,
        show1: false,
        loading: false,
        emailRules: [
            v => !!v || 'Email is required',
            v => /.+@.+\..+/.test(v) || 'Email must be valid',
        ],
        passRules: [
            v => !!v || 'Password es requerido',
        ],
        email: "",
        password: "",
        errorLogin: ""
    }),

    methods: {
        async validate() {
            const isValid = this.$refs.form.validate()

            if (!isValid)
                return

            const payload = {
                email: this.email,
                password: this.password,
            }
            
            this.loading = true
            const response = await this.$store.dispatch('singinUser', payload)
            this.loading = false
            
            this.errorLogin = response.success ? '' : response.msg

            if (response.success)
                this.$router.push('/')
        },
    },

    computed: {
        loggedIn() {
            return this.$store.getters.loggedIn
        },

        currentYear() {
            return '2023'
        },

        confirmExists() {
            return this.$route.query.confirm !== undefined;
        },

        confirmValue() {
            return this.$route.query.confirm === "active" ? 'Your email has been confirmed' : 'Your confirmation has failed, try logging in to receive another confirmation email'
        }
    },

    watch: {
        loggedIn(val) {
            if (val)
                this.$router.push('/')
        }
    },

    created() {
        // HIDEN NAVEGATOR
    }

}
</script>