import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import Toast from "vue-toastification"
import Vuetag from 'vue-gtag'

import "vue-toastification/dist/index.css"

Vue.config.productionTip = false

Vue.use(Toast, {
  transition: "Vue-Toastification__fade",
  maxToasts: 20,
  newestOnTop: true
})

Vue.use(Vuetag, {
  config: {
    id: 'G-NWEDPCVJ1E'
  }
})

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
