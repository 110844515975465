<template>
   <v-card flat class="text-left">
        <v-card-text>
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
            >  
                <v-text-field
                    label="Current password"
                    v-model="password"
                    :rules="passRules"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show1 ? 'text' : 'password'"
                    @click:append="show1 = !show1"
                    v-on:keyup.enter="validate"
                    dense
                    outlined
                    required
                ></v-text-field>

                <v-text-field
                    label="New Password"
                    v-model="newPassword"
                    :rules="passRules"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show1 ? 'text' : 'password'"
                    @click:append="show1 = !show1"
                    v-on:keyup.enter="validate"
                    dense
                    outlined
                    required
                ></v-text-field>

                <v-text-field
                    label="Password confirmation"
                    v-model="comfirmPassword"
                    :rules="confirmationRules"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show1 ? 'text' : 'password'"
                    @click:append="show1 = !show1"
                    v-on:keyup.enter="validate"
                    dense
                    outlined
                    required
                ></v-text-field>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-btn
                v-show="!singupDone"
                color="primary"
                class="ma-2"
                :disabled="loading || !valid"
                @click="updatePassword"
            >
                Update Password
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    export default {
        name: 'UserAccount',

        data: () => ({
            valid: false,
            show1: false,
            loading: false,
            passRules: [
                v => !!v || 'Password is required',
                v => (v && v.length >= 8) || 'Password must be at least 8 characters',
            ],
            password: '',
            newPassword: '',
            comfirmPassword: '',
            errorSingup: '',
            singupDone: false
        }),
        
        computed: {
            loggedIn() {
                return this.$store.getters.loggedIn
            },

            user() {
                return this.$store.getters.user
            },

            userName() {
                return this.user?.user
            },

            userEmail() {
                return this.user?.email
            },

            confirmationRules() {
                return [
                    v => v === this.newPassword || 'Passwords do not match'
                ];
            }
        },

        methods: {
            async updatePassword () {
                const isValid = this.$refs.form.validate()

                if (!isValid)
                    return

                const payload = {
                    password: this.password,
                    newPassword: this.newPassword
                }

                this.loading = true
                const response = await this.$store.dispatch('updateUserPassword', payload)
                this.loading = false

                this.errorSingup = response.success ? '' : response.msg

                if (response.success)
                    this.singupDone = true
            }
        }
    }
</script>