<template>
    <v-container>
    <v-row>
        <v-dialog v-model="dialogToggle" max-width="650px" @change="handleInput">
            <v-card>
                <v-card-title>
                    <span class="text-h5"> {{ cardName }} </span>
                    <div v-if="loggedIn">
                        <v-chip
                            v-if="isSliverCard"
                            class="ma-2"
                            :color="labelColor(inCollectionSliver)"
                            label
                            small
                        >
                            <v-icon left small>
                                mdi-check-circle
                            </v-icon>
                            Sliver
                        </v-chip>
                        <v-chip
                            class="ma-2"
                            :color="labelColor(inCollection)"
                            label
                            small
                        >
                            <v-icon left small>
                                mdi-check-circle
                            </v-icon>
                            Card
                        </v-chip>                    
                        <v-chip
                            class="ma-2"
                            :color="labelColor(inCollectionArt)"
                            label
                            small
                        >
                            <v-icon left small>
                                mdi-check-circle
                            </v-icon>
                            Art
                        </v-chip>
                    </div>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-img
                                    max-width="250"
                                    :src="cardImage"
                                >
                                <div class="gradient" v-show="showFoil"></div>
                                </v-img>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-card
                                    outlined
                                >
                                    <v-list-item three-line>
                                        <v-list-item-avatar
                                            size="30"
                                        >
                                            <img
                                                class="set-cards"
                                                :src="cardSetImage"
                                                alt="Set"
                                            >
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-h6">
                                                {{ cardSet | tittleSet }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                #{{ collectorNumber }} · {{ rarity }} · {{ foilDescription }}
                                            </v-list-item-subtitle>
                                            <!-- <v-list-item-subtitle>
                                                AGREGAR IDIOMAS!
                                            </v-list-item-subtitle> -->
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-card>

                                <v-card
                                    outlined
                                    style="margin-top: 15px;"
                                >
                                    <v-list-item three-line>
                                        <v-list-item-content>
                                            <v-list-item-subtitle>
                                                Price
                                            </v-list-item-subtitle>
                                            <div v-if="loadingInfo">
                                                <v-progress-circular
                                                    :size="30"
                                                    color="primary"
                                                    indeterminate
                                                ></v-progress-circular>
                                            </div>
                                            <div v-else>
                                                <v-list-item-title class="text-h7" v-show="finishesBool.nonfoil">
                                                    $ {{ cardPricesNotFoil }}
                                                </v-list-item-title>
                                                <v-list-item-title class="text-h7" v-show="finishesBool.foil">
                                                    $ {{ cardPricesFoil }} [Foil]
                                                </v-list-item-title>
                                            </div>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-card>
                                <v-card
                                    outlined
                                    style="margin-top: 15px;"
                                >
                                    <v-list-item three-line>
                                        <v-list-item-content>
                                            <v-list-item-subtitle>
                                                Other prints
                                            </v-list-item-subtitle>
                                            <v-row justify="space-around">
                                                <v-tooltip bottom v-for="set in anotherSets" :key="set.card">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-avatar size="30" v-bind="attrs" v-on="on" @click="changeCardBySet(set.name)">
                                                            <img
                                                                class="set-cards"
                                                                :src="set.img"
                                                                :alt="set.name"
                                                            >
                                                        </v-avatar>
                                                    </template>
                                                    <span>{{ set.name }}</span>
                                                </v-tooltip>
                                                <p v-if="anotherSets.length == 0"> None </p>
                                            </v-row>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" text @click="closeDialog()">
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" v-show="showEditBtn" text @click="editCard()">
                        <v-icon>
                            mdi-book-edit
                        </v-icon>
                        Edit
                    </v-btn>
                    <v-btn color="primary" v-show="showAddBtn" text @click="addCard()">
                        <v-icon>
                            mdi-plus-box
                        </v-icon>
                        Add
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
    <AddCard v-model="dialogAddCard" :idCard="uuidCard"></AddCard>
    <EditCard v-model="dialogEditCard" :idCard="uuidCard"></EditCard>
    </v-container>
</template>

<script>
import AddCard from '@/components/AddCardColection'
import EditCard from '@/components/EditCardColection'

export default {
    props: {
        idCard: String,
        typeCard: String,
        value: Boolean
    },

    data: () => ({
        uuidCard: '',
        dialogToggle: false,
        switch1: false,
        switch1Status: false,
        loadingInfo: false,
        dialogAddCard: false,
        dialogEditCard: false,
        inCollection: false,
        inCollectionSliver: false,
        inCollectionArt: false,
        prices: {
            "usd": "0",
            "usd_foil": "0",
        },
        finishesBool: {
            foil: false,
            nonfoil: false,
        }
    }),

    methods: {
        closeDialog () {
            this.dialogToggle = false
        },

        handleInput () {
            this.$emit('input', this.dialogToggle)
        },

        addCard () {
            this.dialogAddCard = true
        },

        editCard () {
            this.dialogEditCard = true
        },

        async getInfoCard (uuid) {
            if (!this.sliverCardExtraInfo)
                await this.$store.dispatch('pullInfoCards', uuid)
        },

        changeCardBySet (set) {
            const findCard = this.sliverCards.find(
                (element) => element.set_name === set && element.name === this.cardName
            )
            
            this.changeCard(findCard.id_sf)
        },

        changeCard (uuid) {
            this.uuidCard = uuid
            this.getInfoCard(uuid)

            if (this.finishes) {
                this.finishesBool = {
                    foil: this.finishes.includes('foil'),
                    nonfoil: this.finishes.includes('nonfoil'),
                }
            }
        },

        labelColor(status) {
            return status ? 'success' : ''
        },

        compareName (a, b) {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        }
    },

    computed: {
        loggedIn() {
            return this.$store.getters.loggedIn
        },

        isSliverCard () {
            return this.typeCard === 'sliver'
        },

        foilVerbose () {
            return this.switch1 ? 'Foil' : 'Not Foil'
        },

        sets () {
            return this.$store.getters.sets
        },
        
        collection() {
            return this.$store.getters.collection
        },

        sliverCards () {
            return this.$store.getters.sliverCards
        },

        sliverView () {
            return this.$store.getters.sliverCardByUuid(this.uuidCard)
        },

        sliverCardExtraInfo () {
            return this.$store.getters.sliverCardExtraInfo(this.uuidCard)
        },

        cardName() {
            return this.sliverView?.name
        },

        cardImage() {
            return this.sliverView?.image_uris?.png
        },

        cardSet() {
            return this.sliverView?.set_name
        },

        cardPricesNotFoil() {
            return this.sliverCardExtraInfo?.prices?.usd
        },

        cardPricesFoil() {
            return this.sliverCardExtraInfo?.prices?.usd_foil
        },

        cardSetImage() {
            const findSet = this.sets.find( e => e.code === this.sliverView?.set )
            return findSet?.icon_svg_uri
        },
        
        showFoil () {
            return this.finishesBool.foil && !this.finishesBool.nonfoil
        },

        anotherSets () {
            let setImages = []

            for (let i = 0; i < this.sliverCards.length; i++) {
                const element = this.sliverCards[i]
                
                const existeSet = setImages.some ( e => e.card === element?.id_sf )

                if ( element.name === this.cardName && element.set_name !== this.cardSet && !existeSet) {
                    const findSet = this.sets.find( e => e.code === element.set )

                    setImages.push({
                        img: findSet?.icon_svg_uri,
                        name: element.set_name,
                        card: element?.id_sf,
                    })
                }
            }

            return setImages.sort(this.compareName)
        },

        rarity () {
            return this.sliverView?.rarity.charAt(0).toUpperCase() + this.sliverView?.rarity.slice(1)
        },

        collectorNumber () {
            return this.sliverView?.collector_number
        },

        finishes () {
            return this.sliverView?.finishes
        },

        foilDescription () {
            let foilDes = ''

            if (!this.finishes)
                return ''

            if (this.finishes.length === 1) {
                foilDes =  this.finishes[0].charAt(0).toUpperCase() + this.finishes[0].slice(1)
            } else if (this.finishes.length === 2) {
                const firstItem = this.finishes[0].charAt(0).toUpperCase() + this.finishes[0].slice(1)
                const secondItem = this.finishes[1].charAt(0).toUpperCase() + this.finishes[1].slice(1)
                foilDes = `${firstItem}/${secondItem}`
            }

            return foilDes
        },

        showAddBtn () {
            return this.loggedIn
        },

        showEditBtn () {
            return this.loggedIn && this.inCollection
        },


    },

    watch: {
        value (val) {
            this.dialogToggle = val
        },

        dialogToggle() {
            this.handleInput()
        },

        idCard (val) {
            this.uuidCard = val
            this.getInfoCard(val)

            if ( this.finishes ) {
                this.finishesBool = {
                    foil: this.finishes.includes('foil'),
                    nonfoil: this.finishes.includes('nonfoil'),
                }
            }
        },
    },

    filters: {
        tittleSet (val) {
            return val
        }
    },

    components: {
        AddCard,
        EditCard
    },

    updated () {
        this.inCollection = this.sliverView?.inCollection
        this.inCollectionSliver = this.sliverView?.inCollectionSliver
        this.inCollectionArt = this.sliverView?.inCollectionArt
    }
}
</script>

<style>
.prints-current-set-symbol {
  width: 25px;
  height: 25px;
}

.gris {
  color: grey;
}

.amarillo {
  color: yellow;
}

.verde {
  color: green;
}

.gradient {
    background: linear-gradient(to bottom,  #ff0000 0%,#00ff00 33%,#0000ff 66%,#ff0000 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff0000', endColorstr='#ff0000',GradientType=0 ); /* IE6-9 */
    background-position: 0 0;
    height: 100%;
    opacity: .25;
    width: 100%;
    animation: colorscroll 4s linear infinite;
}

@keyframes colorscroll {
    from {background-position: 0 0;}
    to {background-position: 0 350px;}
}

.set-cards {
    -webkit-filter: invert(100%); /* Safari/Chrome */
    filter: invert(100%);
}
</style>