<template>
	<v-card
		class="mx-auto mt-5"
		width="1000"
		height="600"
	>
		
		<!-- TODO: PONER TODO A LA IZQUIERDA -->
		<v-tabs vertical>
			<v-tab>
				<v-icon left>
					mdi-account
				</v-icon>
				<v-spacer></v-spacer>
				Account
			</v-tab>
			<v-tab>
				<v-icon left>
					mdi-lock
				</v-icon>
				<v-spacer></v-spacer>
				Profile
			</v-tab>
			<v-tab>
				<v-icon left>
					mdi-email
				</v-icon>
				<v-spacer></v-spacer>
				Email
			</v-tab>

			<v-tab-item>
				<UserAccount></UserAccount>
			</v-tab-item>
			<v-tab-item>
				<UserProfile></UserProfile>
			</v-tab-item>
			<v-tab-item>
				<UserEmail></UserEmail>
			</v-tab-item>
		</v-tabs>
	</v-card>
</template>

<script>
import UserAccount from '@/components/UserAccount'
import UserProfile from '@/components/UserProfile'
import UserEmail from '@/components/UserEmail'

export default {
	data() {
		return {
			tab: null,
		}
	},

	computed: {
		loggedIn () {
			return this.$store.getters.loggedIn
		},
	},

	watch: {
		loggedIn ( val ) {
			if ( !val )
				this.$router.push({ path: '/' })
		}
	},

	components: {
		UserAccount,
		UserProfile,
		UserEmail,
	},

}
</script>