<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <p style="color: white" class="display-1"> Latest Additions</p>
      </v-col>
    </v-row>
    <v-row>
		<v-col cols="12" v-for="entry in entries" :key="entry._id">
			<v-card
				class="mx-auto"
				width="500"
				outlined
			>
				<v-card-title>
					<v-icon
						large
						left
						color="primary"
					>
						{{ iconType(entry.type) }}
					</v-icon>
					<span class="font-weight-light">New {{ showType(entry.type) }} </span>
					
				</v-card-title>
	
				<v-card-text>
					<p class="text-h6 font-weight-bold">
						{{ getInfoName(entry.type, entry.id_target) }}
					</p>
					<p class="text-right">
						{{ entry.ts | dateFormat }}
					</p>
				</v-card-text>
			</v-card>
		</v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment'

export default {
	name: 'HelloWorld',

	data: () => ({

	}),

	computed: {
		entries() {
			return this.$store.getters.entries
		},

		merchandise() {
			return this.$store.getters.merchandise
		},

		sliverCards() {
			return this.$store.getters.sliverCards
		},

	},

	methods: {
		getInfoName ( type, id ) {
			let info = {}
			let text = ''

			if ( type === 'sliver' ||  type === 'art' ) {
				info = this.sliverCards.find(e => e._id == id)
				text = `${info?.name} (${info?.set_name})`
			} else {
				info = this.merchandise.find(e => e._id == id)
				text = info?.name 
			}

			return text
		},

		showType ( type ) {
			if ( type === 'merchandise' )
				return 'sliver merchandice'
			else if ( type === 'art' )
				return 'art sliver card'
			else
				return 'sliver card'
		},

		iconType ( type ) {
			if (type === 'merchandise')
				return 'mdi-cart'
			else if (type === 'art')
				return 'mdi-cards-playing-outline'
			else
				return 'mdi-cards-outline'
		}
	},

	filters: {
		dateFormat (val) {
			return moment(val).format('DD/MM/YYYY HH:mm:ss')
		}
	}

}
</script>
