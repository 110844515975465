<template>
    <v-container
        fluid
    >
        <v-row
            no-gutters
            align="center"
            justify="center"
        >
            <v-col
                cols="12"
                align="center"
                justify="center"
            >
                <v-card
                    class="mx-auto my-15"
                    max-width="800"
                    outlined
                >
                    <v-progress-linear
                        :active="loading"
                        :indeterminate="loading"
                        absolute
                        bottom
                        color="primary"
                    ></v-progress-linear>
                    
                    <div class="text-left mb-4 mt-8 ml-7">
                    </div>
                    <div class="text-left text-h5 ml-7">
                        Contact Us
                    </div>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="8" v-if="!singupDone">
                                    <v-form
                                        ref="form"
                                        v-model="valid"
                                        lazy-validation
                                    >   <v-row>
                                            <v-col cols="12">
                                                <v-text-field
                                                    label="Full Name"
                                                    name="Full Name"
                                                    type="text"
                                                    v-model="fullName"
                                                    v-on:keyup.enter="validate"
                                                    dense
                                                    outlined
                                                    required
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-text-field
                                                    label="Email"
                                                    name="Email"
                                                    type="text"
                                                    hint="You'll need to confirm that this email belongs to you."
                                                    v-model="email"
                                                    :rules="emailRules"
                                                    v-on:keyup.enter="validate"
                                                    dense
                                                    outlined
                                                    required
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-select
                                                    :items="subjects"
                                                    label="Subject"
                                                    v-model="subject"
                                                    dense
                                                    outlined
                                                ></v-select>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-textarea
                                                    outlined
                                                    name="input-7-4"
                                                    label="Message"
                                                    v-model="message"
                                                ></v-textarea>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-col>
                                <v-col cols="12" sm="4" align="center" justify="center">
                                    <img
                                        :src="require('../assets/alone-01.png')"
                                        alt="SLiver Logo"
                                        width="150"
                                        style="vertical-align: middle;"
                                        class="mr-1"
                                    >
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>

                    <v-card-actions class="mb-7">
                        <v-spacer></v-spacer>
                        <v-btn
                            v-show="!singupDone"
                            color="primary"
                            class="ma-2"
                            :disabled="loading || !valid"
                            @click="validate"
                        >
                            Send Message
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

export default {
    data: () => ({
        valid: false,
        loading: false,
        fullName: '',
        email: '',
        subject: '',
        message: '',
        errorSingup: '',
        takenEmail: '',
        takenUser: '',
        singupDone: false,
        subjects: [
            "General",
            "Bug Report",
            "Feature Request",
            "User Interface Feedback",
            "Account Assistance",
            "Donations",
            "Content Suggestions",
            "Advertising Inquiries",
            "Privacy Concerns",
            "Technical Support",
            "Other"
        ]
    }),
    
    methods: {
        async validate() {
            const isValid = this.$refs.form.validate()

            if (!isValid)
                return

            const payload = {
                email: this.email,
                fullName: this.fullName,
                subject: this.subject,
                message: this.message,
            }
            
            this.loading = true
            const response = await this.$store.dispatch('contactData', payload)
            this.loading = false
            
            this.errorSingup = response.success ? '' : response.msg
        },
    },

    computed: {
        confirmationRules() {
            return [
                v => v === this.password || 'Passwords do not match'
            ]
        },

        emailRules () {
            return [
                v => !!v || 'Email is required',
                v => /.+@.+\..+/.test(v) || 'Email must be valid',
                v => v !== this.takenEmail || 'Email already exists'
            ]
        },

        currentYear() {
            return '2023'
        }
    },

}
</script>