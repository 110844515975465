<template>
  <v-container class="pa-4 text-center">
	<v-row>
		<v-card
			class="mx-auto transparent"
			elevation="0"
			width="1000px"
			align="center" justify="center"
		>
			<v-row>
			<v-col cols="12" sm="4">
				<v-text-field v-model="searchName" label="Search" placeholder="Slivers merchandise" outlined dense></v-text-field>
			</v-col>
			</v-row>
		</v-card>
	</v-row>
	<v-row>
		<v-card
			class="mx-auto transparent"
			elevation="0"
			max-width="1000px"
			min-height="600px"
			align="center" justify="center"
		>
			<div class="mb-10">
				<div class="text-sm-left font-weight-thin">
					{{ numberTextShow }} {{ textShow  }} {{ textShowCollectionCards }}
				</div>
				<v-divider></v-divider>
			</div>
			<v-row class="fill-height" align="center" justify="center">
				<template>
					<v-card
						v-for="(item, i) in showPages"
						:key="i"
						class="mx-auto my-12"
						width="300px"
						height="520"
					>
						<div
							class="contenedor-imagen"
							style="position: relative;"
						>
							<v-carousel
								height="300"
								show-arrows-on-hover
							>
								<v-carousel-item
									v-for="(image, i) in item.images"
									:key="i"
									:src="image"
									reverse-transition="fade-transition"
									transition="fade-transition"
									
								></v-carousel-item>
							</v-carousel>
							<v-chip
								v-show="fixInCollection(item._id)"
								style="position: absolute; top: 10px; left: 10px;"
								color="primary"
								dark
								small
							> In collection </v-chip>
						</div>

						<v-card-title>
							<div class="text-left" style="height: 55px;">  {{ item.name }} </div>
						</v-card-title>

						<v-card-text>
							<div class="text-left" style="height: 70px;">  {{ item.description }} </div>
						</v-card-text>

						<v-card-actions>
							<v-btn
								color="primary"
								text
								@click="openLinks(i)"
							>
								Links
							</v-btn>
							<v-spacer></v-spacer>
							<v-btn
								v-if="loggedIn"
								color="primary"
								text
								@click="handleCollection(item)"
							>
								{{ btnText(fixInCollection(item._id)) }}
							</v-btn>
						</v-card-actions>

						<v-expand-transition>
							<v-card
								v-if="revealLink(i)"
								class="transition-fast-in-fast-out v-card--reveal"
							>
								<v-container>
									<v-row>
										<v-col cols="12" v-for="(links, i) in item.links" :key="i">
											<v-card
												outlined
												:href="links.url" target="_blank"
											>
												<v-list-item three-line>
													<v-list-item-content>
														<v-list-item-title class="text-h7">
															{{ links.description }}
														</v-list-item-title>
													</v-list-item-content>
												</v-list-item>
											</v-card>
										</v-col>
									</v-row>
								</v-container>
								<v-card-actions>
								<v-btn
									text
									color="primary"
									@click="closeLinks()"
								>
									Close
								</v-btn>
								</v-card-actions>
							</v-card>
						</v-expand-transition>
					</v-card>
					</template>
			</v-row>
		</v-card>
	</v-row>
    <v-row class="fill-height" align="center" justify="center" v-show="numberPagues > 1">
		<v-pagination
			v-model="page"
			:length="numberPagues"
			color="primary"
		></v-pagination>
    </v-row>

  </v-container>
</template>

<script>

export default {
	data: () => ({
		addCardId: '',
		page: 1,
		searchName: '',
		reveal: false,
		showLinks: -1,
		showInCollection: []
	}),

	computed: {
		loggedIn() {
			return this.$store.getters.loggedIn
		},

		merchandise () {
			return this.$store.getters.merchandise
		},

		hasCollection() {
			return this.$store.getters.collection.length
		},

		filterSliverMerchandise () {
			return this.merchandise.filter((cadena) => cadena.name.toLowerCase().includes(this.searchName.toLowerCase()))
		},
		
		showPages () {
			return this.filterSliverMerchandise.slice((this.page - 1) * 8, this.page * 8)
		},

		numberPagues () {
			return Math.ceil(this.filterSliverMerchandise.length / 8)
		},

		collectionSliver () {
			return this.filterSliverMerchandise.filter(e => e.inCollection)
		},

		initShowNumber () {
			return (this.page - 1) * 8 + 1
		},

		endShowNumber () {
			return this.numberPagues !== this.page ? (this.page * 8) : this.filterSliverMerchandise.length
		},

		numberTextShow () {
			return `${this.initShowNumber} – ${this.endShowNumber} of ${this.filterSliverMerchandise.length}`
		},

		textShow () {
			return this.searchName === "" ? 'Slivers merchandise' : `Slivers merchandise where the name includes “${this.searchName}”`
		},

		textShowCollectionCards() {
			if (!this.loggedIn)
				return ''

			return `· ${this.collectionSliver.length} in Collection`
		},

	},

	methods: {
		openLinks ( index ) {
			this.showLinks = index
		},

		closeLinks () {
			this.showLinks = -1
		},

		revealLink ( index ) {
			return this.showLinks == index
		},

		btnText ( inCollection ) {
			return inCollection ? 'Delete' : 'Add'
		},

		fixInCollection ( id ) {
			return this.showInCollection.includes(id)
		},

		async handleCollection( item ) {
			if ( item.inCollection ) {
				const payload = {
					name: item.name,
					itemId: item.collectionId,
					type: "merchandise"
				}

				this.loading = true
				await this.$store.dispatch('deleteItemCollection', payload)
				let index = this.showInCollection.indexOf(item._id)

				if (index !== -1)
					this.showInCollection.splice(index, 1)

				this.loading = false
			} else {
				const payload = {
					name: item.name,
					itemId: item._id,
					type: "merchandise"
				}

				this.loading = true
				await this.$store.dispatch('addItemCollection', payload)
				this.showInCollection.push(item._id)
				this.loading = false
			}
		},

		async saveItemCollection( id ) {
			const payload = {
				itemId: id,
				type: "merchandise"
			}

			this.loading = true
			await this.$store.dispatch('addItemCollection', payload)
			this.loading = false
		}
	},

	watch: {
		numberPagues() {
			this.page = 1
		}
	},

	created() {
		if (this.loggedIn) {
			if ( !this.hasCollection )
				this.$store.dispatch('pullItemCollection')

			this.$store.dispatch('updateCollection', { type: 'merchandise' })
		}

		this.merchandise.forEach( e => {
			if (e.inCollection)
				this.showInCollection.push(e._id)
		})
	}
}
</script>

<style scoped>
.contenedor-imagen {
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: center; /* Centra horizontalmente */
  align-items: center; /* Centra verticalmente */
  overflow: hidden;
  position: relative;
}

.contenedor-imagen img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  display: block;
  margin: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
  z-index: 2;
}
</style>