<template>
	<div class="confirm">
		<h1>This is an confirm page</h1>
	</div>
</template>

<script>

export default {
	data: () => ({
	}),

	computed: {
		token () {
			return this.$route.query.tcs
		},
	},

	created() {
		if (this.token) {
			fetch(`https://api.slivers.app/api/v1/auth/confirm?tcs=${this.token}`)
			.then(response => {
				console.log("🚀 ~ file: SingupConfirm.vue:14 ~ response:", response)
				this.$router.push({ path: '/singin', query: { confirm: 'active' } })
			})
			.catch(error => {
				this.$router.push({ path: '/singin', query: { confirm: 'fail' } })
				console.error('Fail token', error)
			})
		} else {
			this.$router.push({ path: '/' })
		}
	}

}
</script>