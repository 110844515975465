<template>
   <v-card flat class="text-left">
        <v-card-text>
            <div>User Name</div>
            <p class="text-h5 text--primary">
                {{ userName }}
            </p>
            <div>Email</div>
            <p class="text-h5 text--primary">
                {{  userEmail }}
            </p>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        name: 'UserAccount',
        computed: {
            loggedIn() {
                return this.$store.getters.loggedIn
            },

            user() {
                return this.$store.getters.user
            },

            userName() {
                return this.user?.user
            },

            userEmail() {
                return this.user?.email
            },
        },
    }
</script>