import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

const API_URI = 'https://api.slivers.app/api/v1'
const user = JSON.parse(localStorage.getItem('sliver-collector-314'))
const initialState = user
? { status: { loggedIn: true }, user }
: { status: { loggedIn: false }, user: null };

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		user: initialState.user,
		status: initialState.status,
		drawerView: true,
		sliverCards: [],
		sets: [],
		merchandise: [],
		entries: [],
		collection: [],
		done: null,
		prices: [],
		snackbar: {
			view: false,
			text: '',
			color: 'success'
		},
	},
	getters: {
		loggedIn ( state ) {
			return state.status.loggedIn
		},

		user ( state ) {
			return state.user
		},

		drawerView ( state ) {
			return state.drawerView
		},

		entries ( state ) {
			return state.entries
		},

		sets ( state ) {
			return state.sets
		},

		merchandise ( state ) {
			return state.merchandise
		},

		sliverCards ( state ) {
			return state.sliverCards
		},

		sliverCardByUuid: ( state ) => (uuid) => {
			return state.sliverCards.find(e => e.id_sf === uuid)
		},
		
		collection ( state ) {
			return state.collection
		},
		
		collectionCardByid: ( state ) => (id) => {
			return state.collection.filter(e => e.itemId === id)
		},
		
		sliverCardExtraInfo: (state) => (uuid) => {
			return state.prices.find(e => e.id === uuid)
		},

		snackbar(state) {
			return state.snackbar

		},
	},
	mutations: {
		singinUser ( state, payload ) {
			state.collection = []
			state.user = payload.data
			state.status = { loggedIn: true }
		},

		singoutUser ( state ) {
			state.user = {}
			state.status = { loggedIn: false }
		},

		toggleDrawer( state ) {
			state.drawerView = !state.drawerView
		},

		pullCards ( state, payload ) {
			state.sliverCards = payload.data
		},

		pullInfoCards ( state, payload ) {
			state.prices.push({
				prices: payload.prices,
				id: payload.id
			})
		},
		
		pullSets ( state, payload ) {
			state.sets = payload.data
		},

		pullMerchandise ( state, payload ) {
			state.merchandise = payload.data
		},

		pullEntries ( state, payload ) {
			state.entries = payload.data
		},

		pullItemCollection ( state, payload ) {
			state.collection = payload.data
		},

		// TODO: NO ACTUALIZA CUANDO RESIEN SE AGREGA
		pushItemCollection ( state, payload ) {
			let findItem = false

			for (let i = 0; i < state.collection.length; i++) {
				if (state.collection[i]._id === payload.data._id) {
					state.collection[i] = payload.data
					findItem = true
					break
				}
			}

			if (!findItem) {
				state.collection.push(payload.data)
			}
		},

		loadCardCollection ( state ) {
			state.sliverCards.forEach(objA => {
				objA.inCollection = state.collection.some(objB => objB.itemId === objA._id)
				objA.inCollectionSliver = state.sliverCards.some(objB => objB.inCollection && objB.name === objA.name)
				objA.inCollectionArt = state.sliverCards.some(objB => objB.inCollection && objB.illustration_id === objA.illustration_id)
			})
		},
		
		loadMerchCollection ( state ) {
			state.merchandise.forEach(objA => {
				const findItem = state.collection.find(objB => objB.itemId === objA._id)
				objA.inCollection = findItem != undefined
				
				if ( findItem != undefined ) {
					objA.collectionId = findItem._id
				}
			})
		},

		loadNewCardCollection( state, payload ) {
			const indexItem = state.sliverCards.findIndex(e => e._id === payload)
			const { _id, name, illustration_id } = state.sliverCards[indexItem]

			state.sliverCards[indexItem].inCollection = state.collection.some(objA => objA.itemId === _id)
			state.sliverCards[indexItem].inCollectionSliver = state.sliverCards.some(objA => objA.inCollection && objA.name === name)
			state.sliverCards[indexItem].inCollectionArt = state.sliverCards.some(objA => objA.inCollection && objA.illustration_id === illustration_id)
		},

		loadNewMerchCollection( state, payload ) {
			const indexItem = state.merchandise.findIndex(e => e._id === payload)
			const findItem = state.collection.find(objB => objB.itemId === payload)

			state.merchandise[indexItem].inCollection = findItem != undefined

			if (findItem != undefined)
				state.merchandise[indexItem].collectionId = findItem._id
		},

		editItemCollection ( state, payload ) {
			let index = state.collection.findIndex(e => e._id === payload.itemId)
			
			if (index !== -1) {
				state.collection[index].info.amount = payload.amount
			}
		},

		deleteItemCollection ( state, payload ) {
			state.collection = state.collection.filter(e => e._id !== payload.itemId)

			let index = state.merchandise.findIndex(e => e.collectionId === payload.itemId)
			
			if (index !== -1) {
				state.merchandise[index].inCollection = false
			}
		},

		updateEmailNotifcations ( state, payload ) {
			state.user.emailNotifcations = payload
			saveInLocal(state.user)
		},

		doneState ( state ) {
			state.done = true
		},

		setNotification(state, payload) {
			state.snackbar = payload
		},
	},
	actions: {
		toggleDrawer ({ commit }) {
			commit('toggleDrawer')
		},

		async singinUser ({ commit }, payload) {
			const url = `${API_URI}/auth/singin`
			const { email, password } = payload

			try {
				const response = await axios.post(url, {
					email: email,
					password: password
				})

				commit('singinUser', response.data)
				saveInLocal(response.data.data)

				return response.data
			} catch (error) {
				return error.response.data
			}

		},

		async singupUser ({ commit }, payload) {
			const url = `${API_URI}/auth/singup`
			const { email, password, username } = payload

			try {
				const response = await axios.post(url, {
					email: email,
					password: password,
					username: username
				})

				commit('singinUser', response.data)
				return response.data
			} catch (error) {
				return error.response.data
			}

		},

		async recoverUser ({ commit }, payload) {
			const url = `${API_URI}/auth/recover`
			const { email } = payload

			try {
				const response = await axios.post(url, {
					email: email
				})

				commit('doneState')
				return response.data
			} catch (error) {
				return error.response.data
			}

		},

		async updateUserPassword ({ commit }, payload) {
			const url = `${API_URI}/auth/change`

			try {
				const response = await axios.post(url, payload, { headers: authHeader() })
				commit('doneState')
				
				this.dispatch('customNotification', { text: response.data.msg, color: 'success' })
				return response.data
			} catch (error) {
				this.dispatch('customNotification', { text: error.response.data.msg, color: 'error' })
				return error
			}

		},

		singoutUser ({ commit }) {
			commit('singoutUser')
			cleanInLocal()
		},

		pullCards ({ commit }) {
			const url = `${API_URI}/cards`

			axios.get(url)
			.then((response) => {
				commit('pullCards', response.data)

				if (initialState.status.loggedIn)
					commit('loadCardCollection')
			})
			.catch((error) => {
				console.log(error)
			})
		},

		async pullInfoCards ({ commit }, payload) {
			const url = `https://api.scryfall.com/cards/${payload}`

			try {
				const response = await axios.get(url)
				commit('pullInfoCards', response.data)
				return response.data
			} catch (error) {
				return error
			}
		},

		pullSets ({ commit }) {
			const url = `${API_URI}/sets`

			axios.get(url)
			.then((response) => {
				commit('pullSets', response.data)
			})
			.catch((error) => {
				console.log(error)
			})
		},

		pullMerchandise ({ commit }) {
			const url = `${API_URI}/merchandise`

			axios.get(url)
			.then((response) => {
				commit('pullMerchandise', response.data)

				if (initialState.status.loggedIn)
					commit('loadMerchCollection')
			})
			.catch((error) => {
				console.log(error)
			})
		},

		pullEntries ({ commit }) {
			const url = `${API_URI}/entries`

			axios.get(url)
			.then((response) => {
				commit('pullEntries', response.data)
			})
			.catch((error) => {
				console.log(error)
			})
		},

		async pullItemCollection ({ commit }) {
			const url = `${API_URI}/collection`
			
			try {
				const response = await axios.get(url, { headers: authHeader() })

				commit('pullItemCollection', response.data)
				commit('loadMerchCollection')
				commit('loadCardCollection')
				return response.data
			} catch (error) {
				return error.response.data
			}

		},

		async addItemCollection ({ commit }, payload) {
			const url = `${API_URI}/collection`
			
			try {
				const response = await axios.post(url, payload, { headers: authHeader() })

				commit('pushItemCollection', response.data)

				if (payload.type === "card") {
					commit('loadNewCardCollection', payload.itemId)
					commit('loadCardCollection')
				} else {
					commit('loadNewMerchCollection', payload.itemId)
				}

				this.dispatch('customNotification', {
					text: `${payload.name} added to collection.`,
					color: 'success'
				})

				return response.data
			} catch (error) {
				this.dispatch('customNotification', {
					text: `Error adding card.`,
					color: 'error'
				})

				return error.response.data
			}

		},

		updateCollection({ commit }, payload) {
			if (payload.type === "card")
				commit('loadCardCollection')
			else
				commit('loadMerchCollection')
		},

		async editItemCollection ({ commit }, payload) {
			const { itemId, amount } = payload
			const url = `${API_URI}/collection/${itemId}/amount`

			try {
				const response = await axios.put(url, { amount }, { headers: authHeader() })
				this.dispatch('customNotification', {
					text: `Quantity updated for ${payload.name}.`,
					color: 'success'
				})

				commit('editItemCollection', payload)
				return response.data
			} catch (error) {
				this.dispatch('customNotification', {
					text: `Error editing card.`,
					color: 'error'
				})

				return error.response.data
			}

		},

		async deleteItemCollection ({ commit }, payload) {
			const { itemId } = payload
			const url = `${API_URI}/collection/${itemId}`

			try {
				const response = await axios.delete(url, { headers: authHeader() })
				commit('deleteItemCollection', payload)
				this.dispatch('customNotification', {
					text: `${payload.name} removed from collection.`,
					color: 'success'
				})

				return response.data
			} catch (error) {
				this.dispatch('customNotification', {
					text: `Error removing card.`,
					color: 'error'
				})

				return error.response.data
			}

		},

		async updateEmailNotifcations ({ commit }, payload) {
			const url = `${API_URI}/user/emailnotification`

			try {
				const response = await axios.post(url, payload, { headers: authHeader() })

				commit('updateEmailNotifcations', payload)
				this.dispatch('customNotification', {
					text: `Updated email preferences.`,
					color: 'success'
				})

				return response.data
			} catch (error) {
				this.dispatch('customNotification', {
					text: `Error updating precerences.`,
					color: 'error'
				})

				return error.response.data
			}

		},

		async contactData({ commit }, payload) {
			const url = `${API_URI}/contact`
			const { email, fullName, subject, message } = payload

			try {
				const response = await axios.post(url, {
					email: email,
					fullName: fullName,
					subject: subject,
					message: message,
				})

				commit('doneState')

				this.dispatch('customNotification', {
					text: `Message delivered.`,
					color: 'success'
				})
				
				return response.data
			} catch (error) {
				return error.response.data
			}

		},

		customNotification({ commit }, { text, color }) {
			commit('setNotification', {
				view: true,
				text: text,
				color: color
			})
		},

		closeNotification({ commit }) {
			commit('setNotification', {
				view: false,
				text: '',
				color: 'success'
			})
		},
	},
	modules: {

	}
})

const saveInLocal = (info) => {
	let jsonString = JSON.stringify(info)
	localStorage.setItem('sliver-collector-314', jsonString)
}

const cleanInLocal = () => {
	localStorage.removeItem('sliver-collector-314')
}

const authHeader = () => {
	let user = JSON.parse(localStorage.getItem('sliver-collector-314'))

	if (user && user.token)
		return { 'Authorization': user.token }
	else
		return {}
}