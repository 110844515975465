<template>
    <v-row>
        <v-dialog v-model="dialogToggle" max-width="650px" @change="handleInput">
            <v-card>
                <v-progress-linear
                    :active="loading"
                    :indeterminate="loading"
                    absolute
                    top
                    color="primary"
                ></v-progress-linear>
                <v-card-title>
                    <span class="text-h5"> {{ cardName }}</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-img
                                    max-width="250"
                                    :src="cardImage"
                                >
                                    <div class="gradient" v-show="showFoil"></div>
                                </v-img>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-virtual-scroll
                                    :items="cardCollectionView"
                                    :item-height="100"
                                    height="350"
                                >
                                    <template v-slot:default="{ item }">

                                        <v-card
                                            outlined
                                            :color="selectCard(item._id)"
                                            @click="openEdit(item)"
                                        >  
                                            <v-badge
                                                content="Foil"
                                                :value="item.info.isFoil"
                                                color="green"
                                                offset-x="35"
                                                offset-y="25"
                                                overlap
                                            >
                                                <v-list-item three-line>
                                                    <v-list-item-content>
                                                        <v-list-item-subtitle>
                                                            <span class="text--primary">Language</span> &mdash; {{item.info.leng }}
                                                        </v-list-item-subtitle>
                                                        <v-list-item-subtitle>
                                                            <span class="text--primary">Condition</span> &mdash; {{ item.info.condition }}
                                                        </v-list-item-subtitle>
                                                        <v-list-item-subtitle>
                                                            <span class="text--primary">Amount</span> &mdash; {{ item.info.amount }}
                                                        </v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-badge>
                                        </v-card>
                                    </template>
                                </v-virtual-scroll>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" text @click="closeDialog()">
                        Back
                    </v-btn>
                    <v-spacer></v-spacer>
                    <!-- <v-btn color="blue darken-1" text @click="saveItemCollection()">
                        Add
                    </v-btn> -->
                </v-card-actions>
                <v-expand-transition>
                    <v-card
                        v-if="reveal"
                        class="transition-fast-in-fast-out v-card--reveal"
                    >
                        <v-card-text class="pb-0">
                            <p>Edit amount </p>
                            <p>{{ itemView.language }} · {{ itemView.condition }} · {{ itemView.finishes }} · {{ itemView.amount }}</p>
                            <div class="text-center">
                                <v-btn
                                    class="mx-2"
                                    fab
                                    dark
                                    small
                                    @click="subAmount"
                                >
                                    <v-icon dark>
                                        mdi-minus
                                    </v-icon>
                                </v-btn>
                                <v-chip
                                    class="ma-2"
                                    large
                                >
                                 {{ newAmount }}
                                </v-chip>
                                
                                <v-btn
                                    class="mx-2"
                                    fab
                                    dark
                                    small
                                    @click="addAmount"
                                >
                                    <v-icon dark>
                                        mdi-plus
                                    </v-icon>
                                </v-btn>
                            </div>
                        </v-card-text>
                        <!-- color="teal accent-4" -->
                        <v-card-actions class="pt-0">
                            <v-btn
                                text
                                color="primary"
                                @click="closeEdit"
                            >
                                Back
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="emptyCards"
                            >
                                Empty
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="saveCards"
                            >
                                Save
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                    </v-expand-transition>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
export default {
    props: {
        idCard: String,
        value: Boolean
    },

    data: () => ({
        dialogToggle: false,
        switch1: false,
        switch1Status: true,
        loading: false,
        amount: 1,
        condition: 'Mint(M)',
        language: 'English',
        reveal: false,
        newAmount: null,
        itemID: null,
        itemView: {
            language: '',
            condition: '',
            amount: 0,
            finishes: '',
            isFoil: false
        }
    }),

    methods: {
        closeDialog () {
            this.dialogToggle = false
            this.reveal = false
        },

        handleInput() {
            this.$emit('input', this.dialogToggle)
        },

        foilVerbose (isFoil) {
            return isFoil ? 'Foil' : 'Not Foil'
        },

        openEdit ( data ) {
            this.itemID = data._id

            this.itemView = {
                language: data.info.leng,
                condition: data.info.condition,
                amount: data.info.amount,
                finishes: this.foilVerbose(data.info.isFoil),
                isFoil: data.info.isFoil
            }

            this.reveal = true
            this.newAmount = data.info.amount
        },

        closeEdit () {
            this.reveal = false
            this.itemID = null
            this.itemView = {
                language: '',
                condition: '',
                amount: 0,
                finishes: '',
                isFoil: false
            }
        },

        emptyCards () {
            this.newAmount = 0
        },

        selectCard ( id ) {
            return this.itemID === id ? 'success' : ''
        },

        async saveCards () {
            const payload = {
                name: this.cardName,
                itemId: this.itemID,
                amount: +this.newAmount
            }

            if ( this.newAmount > 0 ) {
                this.loading = true
                await this.$store.dispatch('editItemCollection', payload)
                this.loading = false
            } else {
                this.loading = true
                await this.$store.dispatch('deleteItemCollection', payload)
                this.loading = false
            }

            this.closeEdit()
            this.closeDialog()
        },

        addAmount () {
            this.newAmount += 1
        },

        subAmount () {
            this.newAmount -= 1

            if ( this.newAmount < 0)
                this.newAmount = 0
        },

    },

    computed: {
        
        sliverView () {
            return this.$store.getters.sliverCardByUuid(this.idCard)
        },

        cardCollectionView () {
            return this.$store.getters.collectionCardByid(this.cardId)
        },

        cardId () {
            return this.sliverView?._id
        },

        cardName () {
            return this.sliverView?.name
        },

        cardImage () {
            return this.sliverView?.image_uris?.png
        },

        cardSet () {
            return this.sliverView?.set_name
        },
        
        showFoil () {
            return this.itemView.isFoil
        }
    },

    watch: {
        value (val) {
            this.dialogToggle = val
        },

        dialogToggle( val ) {
            this.handleInput()

            if (!val) 
                this.closeEdit()
        },

        amount (val) {
            if (val < 0)
                this.amount = +1
        }

    },

    created () {
        
    }

    
}
</script>


<style scoped>
.contenedor-imagen {
    width: 300px;
    height: 300px;
    display: flex;
    justify-content: center;
    /* Centra horizontalmente */
    align-items: center;
    /* Centra verticalmente */
    overflow: hidden;
    position: relative;
}

.contenedor-imagen img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    display: block;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.v-card--reveal {
    bottom: 0;
    opacity: 1 !important;
    position: absolute;
    width: 100%;
    z-index: 2;
}
.gradient {
    background: linear-gradient(to bottom, #ff0000 0%, #00ff00 33%, #0000ff 66%, #ff0000 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff0000', endColorstr='#ff0000', GradientType=0);
    /* IE6-9 */
    background-position: 0 0;
    height: 100%;
    opacity: .25;
    width: 100%;
    animation: colorscroll 4s linear infinite;
}

@keyframes colorscroll {
    from {
        background-position: 0 0;
    }

    to {
        background-position: 0 350px;
    }
}
</style>