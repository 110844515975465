import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import SliverCards from '../views/ViewCards.vue'
import ArtCards from '../views/ViewArtCards.vue'
import Products from '../views/ViewProducts.vue'
import User from '../views/UserView.vue'
import SinginView from '../views/SinginView.vue'
import SingupView from '../views/SingupView.vue'
import recorverView from '../views/recorverView.vue'
import SingupConfirm from '../views/SingupConfirm.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import ContactView from '../views/contactView.vue'
import store from '../store'


Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Sliver Collector',
		component: HomeView
	},
	{
		path: '/slivers',
		name: 'Slivers Cards',
		component: SliverCards
	},
	{
		path: '/art',
		name: 'Slivers Art Cards',
		component: ArtCards
	},
	{
		path: '/products',
		name: 'Sliver Merchandice',
		component: Products
	},
	{
		path: '/singin',
		name: 'Singin',
		component: SinginView
	},
	{
		path: '/singup',
		name: 'Singup',
		component: SingupView
	},
	{
		path: '/recover',
		name: 'recover',
		component: recorverView
	},
	{
		path: '/user',
		name: 'My account',
		component: User
	},
	{
		path: '/confirm',
		name: 'Confirm',
		component: SingupConfirm
	},
	{
		path: '/policy',
		name: 'Privacy Policy',
		component: PrivacyPolicy
	},
	{
		path: '/contact',
		name: 'Contact',
		component: ContactView
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

let isInitialize = false
const user = JSON.parse(localStorage.getItem('sliver-collector-314'))

router.beforeEach((to, from, next) => {

	if ( !isInitialize ) {
		isInitialize = true
		store.dispatch('pullCards')
		store.dispatch('pullMerchandise')
		store.dispatch('pullEntries')
		store.dispatch('pullSets')

		if ( user )
			store.dispatch('pullItemCollection')
	}

	next()
})

export default router
